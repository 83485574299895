<template>
    <div class="pdf-container">
        <!-- vue-pdf component -->
        <vue-pdf-app :pdf="pdfUrl" :page-scale="is_mobile?'page-width':'100'" />
    </div>
</template>

<script setup>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import utils from '@/utils';
import { ref } from 'vue';

const is_mobile = ref(utils.methods.isMobile())

const props = defineProps({
    pdfUrl: {
        type: String,
        required: true
    }
});
</script>

<style scoped>
.pdf-container {
    width: 100%;
    height: 65vh;
    overflow: auto;
}
</style>