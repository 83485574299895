<template>
  <div class="goldie-silversaver">
    <h5>Credit Quarter AB</h5>
    <h5>{{ texts.terms[store.state.lang] }}</h5>
    <PdfViewer :pdfUrl="pdfUrl" />
    <div class="cnlbtncon">
      <button class="cnlbtn" @click="goToCreditQuarter">{{ texts.go_to_cq[store.state.lang] }}</button>
    </div>
  </div>
</template>

<script setup>

import { ref } from 'vue';
import router from '@/router';
import utils from '@/utils';
import languages from "@/assets/translations/_languages.json"
import texts from "@/assets/translations/All.json"
import { useStore } from 'vuex';
import PdfViewer from '@/components/PdfViewer.vue';


const store = useStore();



const language = ref()
language.value = router.currentRoute.value.query.l

function codeExists(code) {
  return languages.some(language => language.code === code);
}

if (language.value && codeExists(language.value)) {
  store.state.lang = language.value
}


const pdfUrl = ref('https://qglxpecfznkh.blob.core.windows.net/terms/goldie-silversaver_' + store.state.lang + '.pdf');


const goToGoldie = () => {
  window.location.href = process.env.VUE_APP_GOLDIE_RETURN_URL
}

const goToCreditQuarter = () => {
  window.location = "https://creditquarter.com"
}

</script>

<style scoped>
.cnlbtncon {
  margin-top: 5vh;
}

.cnlbtn {
  padding: 10px;
  background-color: #455C84;
  color: white;
  font-weight: 600;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  min-width: 200px;

}
</style>