import { Http } from "@capacitor-community/http";
import { Capacitor } from "@capacitor/core";
import store from "./store"
import router from "./router"
import { v4 as uuidLibraryV4 } from 'uuid';

export default {
  install() { },
  methods: {
    async be(urlending, paramskw) {
      const url = process.env.VUE_APP_BE_BASE_URL + urlending;
      let request_data = paramskw;
      const options = {
        url: url,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "B6Cbgjoe": process.env.VUE_APP_CQ_PUBLISHABLE_KEY
        },
        data: request_data,
      };
      try {
        const resp = await Http.post(options)
        return resp.data;

      } catch (e) {
        //empty
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isNative() {
      if (["ios", "android"].includes(Capacitor.getPlatform())) {
        return true;
      } else {
        return false;
      }
    },
    makeId() {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 12) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    uuid4() {
      return uuidLibraryV4();
    },
    isEmailValid(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    scrollToDiv(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    formatTimestampToYMD(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() is 0-indexed
      const day = date.getDate();
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
  },
};
