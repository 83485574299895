<template>
  <div class="page404">
    <h1>
      {{texts.page_not_found[store.state.lang]}}
    </h1>
    {{texts.redirecting_to[store.state.lang]}} <a href="https://creditquarter.com">CreditQuarter</a>
  </div>
</template>

<script setup>
import texts from "@/assets/translations/All.json"
import { useStore } from 'vuex';

const store = useStore();

setTimeout(() => {
  window.location = "https://creditquarter.com"
}, 5000)

</script>
<style scoped>
.page404 {
  padding-top: 50px;
}
</style>