import { createRouter, createWebHistory } from 'vue-router'
import GoldieSilversaver from '@/views/GoldieSilversaver.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/goldie-silversaver',
    name: 'GoldieSilversaver',
    component: GoldieSilversaver
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
